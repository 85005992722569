import { useLocation, useParams } from 'react-router';
import { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import PositionSelectionGroup from '../position-selection-group/position-selection-group.component';
import HeaderToolbar from '../../../../components/Common/headers/header/header-toolbar/header-toolbar.component';
import Header from '../../../../components/Common/headers/header/header.component';
import { useGetPositionByIdQuery } from '../../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import { PAGE_TITLES } from '../../../../consts';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { PageName } from '../../../../enums/page-name.enum';
import {
  Body, LayoutContainer, LayoutHeader, SelectionGroupContainer,
} from './position-layout.styled';

const PositionLayout = ({ children }: PositionLayoutProps): JSX.Element | null => {
  const { positionId } = useParams<{ positionId: string }>();
  const location = useLocation();
  const history = useHistory();
  const pageName = AppRouting.getPageName(location.pathname);
  const {
    data: position, isLoading, isError,
  } = useGetPositionByIdQuery(positionId);

  useEffect(() => {
    if (position?.id && !isLoading) {
      document.title = `${position.jobTitle}${PAGE_TITLES.CUSTOM_PAGE_SUFFIX}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, position?.id]);

  if (position.id && position.hasReachedMatchTuneThreshold && pageName === PageName.Match) {
    history.replace(AppRouting.buildMatchTunePagePath(positionId));
  }

  if (isError) {
    return <Redirect to={ AppRouting.ROUTES.HOME } />;
  }

  return (
    <LayoutContainer>
      <Header>
        <LayoutHeader>
          <HeaderToolbar positionId={ positionId } />
          <SelectionGroupContainer>
            <PositionSelectionGroup
              positionId={ positionId }
            />
          </SelectionGroupContainer>
        </LayoutHeader>
      </Header>
      <Body>
        { children }
      </Body>
    </LayoutContainer>
  );
};

export default PositionLayout;
